
export const move = (direction,dur) => {

    return {
        before : 
            { 
                translateX: direction=== 'left' ? -500 : 0 ,
                translateY: direction === 'right' ? 1000 : 0
            },
        after : 
            { 
                translateX: 0 , 
                translateY : 0,
                transition : 
                {
                    duration : dur
                },
            },
        
    }

}
