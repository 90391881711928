import React from 'react'
import '../commons/Common.css'


function MemberAbout({memberDetails})
{
    return (
    <div className='team-member-profile-details'>
        <h1 className='team-member-profile-details-name'>{memberDetails.name}</h1>
        <h1 className='team-member-profile-details-designation'>{memberDetails.designation}</h1>
        <h1 className='team-member-profile-details-about'>{memberDetails.about}</h1>
        </div>);
}

function MemberImage({imagePath})
{
    return (<div className='team-member-profile-img background-black border-r'>
      <img className='img-p' src={imagePath}/>
    </div>);
}

function TeamLeft({teamMember}) {
    return (
      <div className='team-member border-b'>
          <div className='blank-component border-r'></div>
          <div className='blank-component border-r'></div>
          <MemberImage imagePath={teamMember.image}/>
          <div className='blank-component border-r'></div>
          <MemberAbout memberDetails={teamMember}/>
      </div>
    )
  }

  function TeamRight({teamMember}) {
    return (
      <div className='team-member border-b'>
          <div className='blank-component border-r'></div>
          <div className='blank-component border-r'></div>
          <MemberAbout memberDetails={teamMember}/>
          <div className='blank-component border-l border-r'></div>
          <MemberImage imagePath={teamMember.image}/>
      </div>
    )
  }

function TeamMember({teamMember}) {
  return (teamMember.id % 2 === 1) ? TeamLeft({teamMember}) : TeamRight({teamMember})
}

export default TeamMember