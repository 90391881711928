import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './resources/HomePage.css'
import '../commons/Common.css'


function AboutEclipticon() {
  return (
          <div className='about-eclipticon-container component-row'>
            <div className='about-eclipticon-col1 border-r'>
              <div className='about-eclipticon-welcome-text-box'><h1>Welcome to</h1></div>
              <div className='component-row'>
                <div className='about-eclipticon-company-name-line border-t'></div>
                <div className='about-eclipticon-company-name-text-box'><h1>ECLIPTICON</h1></div>
              </div>
              <div className='about-eclipticon-welcome-message-text-box about-eclipticon-welcome-message-text'>Where every click, swipe, and tap is an experience in itself.</div>
              <div className='about-eclipticon-col1-row2 border-t border-b'></div>
            </div>
            <div className='about-eclipticon-col2 border-r border-b'>
              <div className='about-eclipticon-col2-row1 border-b'></div>
            </div>
            <div className='about-eclipticon-col3'>
            <div className='about-eclipticon-col2-row1 border-b'></div>
            </div>

          </div>




    // <div className='HomePageComponent-about-eclipticon'>
    //     <div className='row HomePageComponent-about-eclipticon-component-row1'>
    //     <div className='col border-r'></div>
    //     <div className='col-2 border-r'></div>
    //     <div className='col-2'></div>
    //     </div>
    //     <div className='row HomePageComponent-about-eclipticon-component-row1'>
    //     <div className='col-2 border-t'></div>
    //     <div className='col border-r'></div>
    //     <div className='col-2 border-r border-b'></div>
    //     <div className='col-2 border-b'></div>
    //     </div>
    //     <div className='row HomePageComponent-about-eclipticon-component-row1'>
    //     <div className='col border-r'></div>
    //     <div className='col-2 border-r'></div>
    //     <div className='col-2'></div>
    //     </div>
    //     <div className='row HomePageComponent-about-eclipticon-component-row1'>
    //     <div className='col-2 border-t border-b'><h></h></div>
    //     <div className='col border-r border-t border-b'></div>
    //     <div className='col-2 border-r border-b'></div>
    //     <div className='col-2'></div>
    //     </div>
    // </div>
  )
}

export default AboutEclipticon
