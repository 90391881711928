import React from 'react'
import './resources/Consulting.css'
import '../commons/Common.css'
import consultingJson from './resources/Consulting.json'

import { ReactComponent as SeeMoreArrow } from './resources/seemore_arrow.svg';

import { Link } from 'react-router-dom'



import { ReactComponent as IdeaIcon } from './resources/carbon_idea.svg';
import { ReactComponent as BorderIcon } from './resources/consulting-page-icon.svg';
import { ReactComponent as SustainabilityIcon } from './resources/streamline_target.svg';
import { ReactComponent as TransformationIcon } from './resources/ri_shake-hands-fill.svg';
import { ReactComponent as OperationsIcon } from './resources/ri_todo-line.svg';

function Consulting() {
    let sendmail = () => { window.location.href = "mailto:kshitij@eclipticon.com?subject=Subject&body=message%20goes%20here"; }

    return (
        <div className='component-col'>
            <div className='consulting-container border-b'>
                <div className=' component-row consulting-our-appraoch-row1'>
                    <div className='consulting-container-common-col1 border-r'></div>
                    <div className='consulting-our-appraoch-col2 border-r'>
                        <div className='our-appraoch-text-box'><h1>{consultingJson.title}</h1></div>
                        <div className='consulting-our-appraoch-col2-half-line border-b'></div>
                    </div>
                    <div className='consulting-container-common-col3 border-r'></div>
                    <div className='consulting-container-common-col4'></div>
                </div>
                <div className='component-row consulting-our-appraoch-row2'>
                    <div className='consulting-container-common-col1 background-off-white border-r'></div>
                    <div className='consulting-our-appraoch-col2 border-r'>
                        <div className='consulting-container-text-box consulting-container-keypoints-text'>
                            {
                                <ul>
                                    {consultingJson.details.map((line) => (
                                        <li>{line}</li>
                                    ))}
                                </ul>
                            }
                        </div>
                        <button className='consulting-container-see-more product-see-more-button-border component-row' onClick={sendmail}>
                            <h1>{consultingJson.connectButtonName}</h1>
                            <SeeMoreArrow className='consulting-container-see-more-arrow' />
                        </button>
                    </div>
                    <div className='component-col'>
                        <div className='consulting-container-icon-col3 background-off-white'>
                            <BorderIcon className='consulting-container-icon-box'></BorderIcon>
                        </div>
                        <div className='consulting-container-common-row2-col3-4 component-row'>
                            <div className='consulting-container-common-col3 border-r'></div>
                            <div className='consulting-container-common-col4'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='consulting-container-our-appraoch border-b'>
                <div className=' component-row consulting-our-appraoch-row1 border-b'>
                    <div className='consulting-container-common-col1 background-off-white border-r'></div>
                    <div className='consulting-our-appraoch-col2 border-r'>
                        <div className='our-appraoch-text-box'><h1>OUR APPROACH</h1></div>
                    </div>
                    <div className='consulting-container-common-col3 background-black border-r'></div>
                    <div className='consulting-container-common-col4'></div>
                </div>
                <div className=' component-row consulting-our-appraoch-row2'>
                    <div className='consulting-container-common-col1 border-r'></div>
                    <nav className='consulting-our-appraoch-col2 border-r'>
                        <div className='component-row'>
                            <Link to='/consulting/Idea' className='consulting-our-appraoch-row2-col2 border-r border-b'>
                                <IdeaIcon className='our-appraochs-icon'></IdeaIcon>
                                <div className='our-appraochs-text'><h1>Idea</h1></div>
                            </Link>
                            <Link to='/consulting/Sustainability' className='consulting-our-appraoch-row2-col2 border-b'>
                                <SustainabilityIcon className='our-appraochs-icon'></SustainabilityIcon>
                                <div className='our-appraochs-text'><h1>Sustainability</h1></div>
                            </Link>
                        </div>
                        <div className='component-row'>
                            <Link to='/consulting/Operations' className='consulting-our-appraoch-row2-col2 border-r border-b'>
                                <OperationsIcon className='our-appraochs-icon'></OperationsIcon>
                                <div className='our-appraochs-text'><h1>Operations</h1></div>
                            </Link>
                            <Link to='/consulting/Transformation' className='consulting-our-appraoch-row2-col2 border-b'>
                                <TransformationIcon className='our-appraochs-icon'></TransformationIcon>
                                <div className='our-appraochs-text'><h1>Transformation</h1></div>
                            </Link>
                        </div>
                    </nav>
                    <div className='consulting-container-common-col3 background-black border-b border-r'></div>
                    <div className='consulting-container-common-col4'></div>
                </div>
            </div>
        </div>
    )
}

export default Consulting
