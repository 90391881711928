import React from 'react'
import './resources/HomePage.css'
import WhyEclipticon from './WhyEclipticon'
import WhatNew from './WhatNew'
import WhoAreWe from './WhoAreWe'
import AboutEclipticon from './AboutEclipticon'
import FooterPart1 from '../footer/FooterPart1'


function HomePage() {
  return (
    <div>
        <div id ='about-eclipticon' className='HomePageComponent-about-eclipticon'><AboutEclipticon/></div>
        <WhoAreWe/>
        <WhyEclipticon/>
        <div id ='what-is-new' className='what-is-new'><WhatNew/></div>
        <FooterPart1/>
    </div>
  )
}

export default HomePage
