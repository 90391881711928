import React from 'react'
import './Footer.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../commons/Common.css'
import { Link } from 'react-router-dom';

import { ReactComponent as PhoneIcon } from './phone.svg';
import { ReactComponent as LocationIcon } from './location.svg'

import {ReactComponent as TwitterIcon} from '../commons/social-media-icon/mdi_twitter.svg'
import {ReactComponent as InstaIcon} from '../commons/social-media-icon/gg_instagram.svg'
import {ReactComponent as FacebookIcon} from '../commons/social-media-icon/devicon-plain_facebook.svg'
import {ReactComponent as LinkedInIcon} from '../commons/social-media-icon/fa-brands_linkedin.svg'




function FooterPart2() {
  let findUsOnText = "Find Us On"
  let address = "E-28, Ashirwad Nagar Shobhagpura \n Udaipur,Rajasthan IN \n 313001 \n Our office is open: \n Monday–Friday 9am-6pm \n Privacy Policy and Terms of Service";
  let contact = "+91 - 6367164278 \n Kshitij Khatri \n Monday–Friday 9am-6pm"
  return (
    <div className='footer-container2'>
        <div className='component-row'>
            <div className='footer-container2-col1 border-r'></div>
            <div className='footer-container2-col2 component-col border-r'>
              <div className='footer-container2-col2-row1 border-b' />
              <div className='footer-container2-col2-row2 component-row border-b'>
              <PhoneIcon size='2.2em'className='footer-phone-icon'/>
              <div className='footer-phone-text-box footer-icons-text'>
              {contact.split('\n').map((line, index) => (
               <React.Fragment key={index}>
                 {line}
                 <br />
                </React.Fragment>
                ))}
              </div>
              </div>
              <div className='footer-container2-col2-row1 component-row'>
              <LocationIcon  size='2.2em'  className='footer-location-icon'/>
              <div className='footer-location-text-box footer-icons-text'>
              {address.split('\n').map((line, index) => (
               <React.Fragment key={index}>
                 {line}
                 <br />
                </React.Fragment>
                ))}
              </div>
              </div>
            </div>
            <div className='footer-container2-col3 border-r'>
              <div className='footer-navigation-bar-box'>
                <nav className='component-col'>
                  <Link to='/products' className='footer-navigation-bar-box-text-style'>Product</Link>
                  <Link to='/services' className='footer-navigation-bar-box-text-style footer-navigation-bar-margin'>Services</Link>
                  <Link to='/consulting' className='footer-navigation-bar-box-text-style footer-navigation-bar-margin'>Consultancy</Link>
                  <Link to='/company' className='footer-navigation-bar-box-text-style footer-navigation-bar-margin'>Company</Link>
                  <Link to='/company' className='footer-navigation-bar-box-text-style footer-navigation-bar-margin'>Careers</Link>
                  <Link to='/company' className='footer-navigation-bar-box-text-style footer-navigation-bar-margin'>Blogs</Link>
                </nav>
              </div>
            </div>
            <div className='footer-container2-col4'>
              <div className='footer-container2-col4-row1 border-b'></div>
              <div className='footer-icons-container'>
                <h6>{findUsOnText}</h6>
                  <div className='footer-icons-box'>
                  <LinkedInIcon className='footer-icon-linkedin' onClick={() => window.open('https://www.linkedin.com/company/eclipticon')}/>
                  <FacebookIcon className='footer-icon-fb' onClick={() => window.open('https://www.facebook.com/people/Eclipticon/61563378852153/')}/>
                  <TwitterIcon  className='footer-icon-twitter'  onClick={() => window.open('https://x.com/TheEclipticon')}/>
                  <InstaIcon  className='footer-icon-insta'  onClick={() => window.open('https://www.instagram.com/eclipticonofficial/')}/>
                  </div>
              </div>
            </div>
            </div>
        </div>
  )
}
export default FooterPart2  
