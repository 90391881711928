import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './resources/HomePage.css'
import '../commons/Common.css'
import WhoAreWeJson from './resources/WhoAreWe.json'

import { motion } from "framer-motion"
// variants
import {move} from '../../animation/variants/variants'
import {grow} from '../../animation/variants/grow'


function WhoAreWe() {
  return (
    <div className='who-are-we-container border-b'>
                <table>
                 <motion.tr className='border-b'
                  variants ={move('left',1)} 
                  initial = 'before'
                  whileInView= 'after'
                  viewport={{once:true}}
                >
                    <th className='who-are-we-th border-r'></th>
                    <th className='who-are-we-th1'>
                      <div className='who-are-we-title'><h1>{WhoAreWeJson.title}</h1></div>
                    </th>
                 </motion.tr>
                 </table>
                <table>
                 <tr> 
                    <td className='who-are-we-td border-r'></td>
                    <td className='who-are-we-td1 border-r border-b'>
                    <div className='who-are-we-text'>
                      <h1>{WhoAreWeJson.text}</h1>
                    </div>
                    </td>
                    <td className='who-are-we-td-test border-b'></td>
                 </tr>
             
              </table>
              <table>
              <tr>
              <th className='who-are-we-td2 border-r'></th>
              <th className='who-are-we-td21'></th>
              </tr>
              </table>
              
                
    </div>
  )
}

export default WhoAreWe
