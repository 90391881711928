import { transform } from "framer-motion"


export const grow = (direction,dur) => {

    return {
        before : 
            { 
                scaleX: 1,
                scaleY: 0
            },
        after : 
            { 
                scaleX: 1  , 
                scaleY : 1,
                
                transition : 
                {
                    duration : dur
                },
            },
        
    }

}