import React from 'react'
import './resources/WhatIsNew.css'
import '../commons/Common.css'
import HotCard from '../whatsnew/HotCard';

import { motion } from "framer-motion"
// variants
import { move } from '../../animation/variants/variants'
import { grow } from '../../animation/variants/grow'


function WhatNew() {
  const messageText = 'What\'s New';

  return (
    <div className='what-is-new-container component-row border-b'>
      <motion.div
        variants={grow('left', 1)}
        initial='before'
        whileInView='after'
        viewport={{ once: true }}
        className='what-is-new-container-col1 border-r'>

      </motion.div>
      <div className='what-is-new-container-col2 component-row'>
        <div className='what-is-new-container-col21'>
          <motion.div
            variants={move('left', 1)}
            initial='before'
            whileInView='after'
            viewport={{ once: true }}
            className='what-is-new-container-text-box'>
            <h1>What’s New</h1>
          </motion.div>
        </div>
        <div className='what-is-new-container-col22 border-l border-r'>
          <div className='what-is-new-container-hotcard-box background-white component-row'>
            <HotCard />
          </div>
        </div>
      </div>
      <div className='what-is-new-container-col3'></div>




      {/* <div className='what-is-new-container-col2 border-l'>
          <div className='what-is-new-container-header-text-box what-is-new-container-text'>{messageText}</div>
        </div>
        <div className='what-is-new-container-col3 component-col border-l'>
          <HotCard></HotCard>
        </div> */}
      {/* <div className='what-is-new-container-col4 border-l'></div> */}
    </div>
  )
}

export default WhatNew
