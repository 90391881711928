import React from 'react'
import './resources/HotCard.css'

function HotCard() {
  return (
    <a href='/products#frenley' className='hot-card'>
      <div className='hot-card-text-box'>
        <h1>Frenley</h1>
        <p>Ready to redefine your love story? Download Frenley – Where Every Connection is a Spark, Every Swipe is an Adventure....</p>
      </div>
    </a>
  )
}

export default HotCard
