import React from 'react'
import './Footer.css'
import FooterPart1 from './FooterPart1'
import FooterPart2 from './FooterPart2'


const Footer = () => {
  return (
    <div>
      {/* <FooterPart1/> */}
      <FooterPart2/>
    </div>
  )
}

export default Footer
