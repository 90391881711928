import React from 'react';
import './ComingSoon.css';
import WhiteLogo from './resources/WhiteLogo.svg';
import instagram from './resources/linkedin.svg';
import facebook from './resources/facebook.svg';
import twitter from './resources/instagram.svg';
import linkedin from './resources/twitter.svg';

const ComingSoon = () => {
  return (
    <div className='eclip'>
        <div className='eclips'>
           <h1>ECLIPTICON</h1>
 </div>



 <div className='images'>
    <img className='imagesss' src={WhiteLogo} alt="whitelogo" />

 </div>
 <div className="comming">
    <h1>Coming Soon</h1>
 </div>

{/* <div className="icon">
<div className="find">
    <p>Find Us On :</p>

<div className='linked'>
        <img src={linkedin} alt="" />
    </div>
    <div className='facebook'>
        <img src={facebook} alt="" />
    </div>
    <div className='twitter'>
        <img src={twitter} alt="" />
    </div>

    <div className='insta'>
        <img src={instagram} alt="" />
    </div>

</div>
</div> */}
</div>
  )
}

export default ComingSoon;