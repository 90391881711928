import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './NavigationBar.css'
import '../commons/Common.css'
import {Link} from 'react-router-dom'

import {ReactComponent as CompanyLogo} from './company-logo.svg';

function NavigationBar() {
  const navigationBarList = ['ECLIPTICON','Home','Products','Services','Consulting','Company']; 

  function CustomLink({to,children,...props})
  {
    const path = window.location.pathname;

    return(<div className={path===to ? 'col-2 navigationbar-component active':'col-2 navigationbar-component'}>
      <Link to={to} {...props}>{children}</Link>
    </div>);

  }

  return (
    <nav className='navigationbar-container border-b'>
      <Link to='/' className='navigationbar-home-icon-container'>
        <CompanyLogo className='navigationbar-home-icon1'></CompanyLogo>
        <div className='navigationbar-home-text'><h4>{navigationBarList[0]}</h4></div>
      </Link>
      <Link to='/' className='navigationbar-link border-l border-r'><h4>{navigationBarList[1]}</h4></Link>
      <Link to='/products' className='navigationbar-link border-r'><h4>{navigationBarList[2]}</h4></Link>
      <Link to='/services' className='navigationbar-link border-r'><h4>{navigationBarList[3]}</h4></Link>
      <Link to='/consulting' className='navigationbar-link border-r'><h4>{navigationBarList[4]}</h4></Link>
      <Link to='/company' className='navigationbar-link'><h4>{navigationBarList[5]}</h4></Link>
    </nav>
  )
}

export default NavigationBar
