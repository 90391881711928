import React from 'react'
import './resources/Company.css'
import '../commons/Common.css'
import teamMembersJson from './resources/Team.json'
import TeamMember from './TeamMember';

const renderTeamMember = () => {
  const teamMembersComponent = [];
  teamMembersJson.map((member, i) => (
    teamMembersComponent.push(<TeamMember teamMember={member}/>)
))
  return teamMembersComponent;
}

function OurTeam()
{
  return (<div className='our-team-container border-b'>
    <div className='blank-component border-r'></div>
    <div className='blank-component border-r'></div>
    <div><h1 className='our-team-text'>Our Team</h1></div>
  </div>);
}

function Team() {
  return (
    <div className=''>
      {OurTeam()}
      {renderTeamMember()}
    </div>
  )
}

export default Team