import React from 'react'
import NavigationBar from '../component/navigationbar/NavigationBar'

function Navigation() {
  return (
    <div>
      <NavigationBar></NavigationBar>
    </div>
  )
}

export default Navigation
