import React from 'react'
import './Footer.css'
import '../commons/Common.css'
import {ReactComponent as SeeMoreArrow} from '../consulting/resources/seemore_arrow.svg';




function FooterPart1() {
    let sendmail = () => {window.location.href = "mailto:kshitij@eclipticon.com?subject=Subject&body=message%20goes%20here";}
  return (
    <div className='FooterPart1 footer-container1 component-row border-b'>
        <div className=''>
        <h1 className='footer-container1-text1 component-col'>INTRIGUED?</h1>
        <h2 className='footer-container1-text2'>You havent seen anything yet</h2>
        </div>
        <button className='button-see-more product-see-more-button-border component-row' onClick={sendmail}>
                <h1>Lets Talk</h1>
                <SeeMoreArrow className='button-see-more-arrow'/>
        </button>
    </div>
  )
}

export default FooterPart1
