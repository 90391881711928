import React from 'react'
import './Products.css'
import '../commons/Common.css'
import '../consulting/resources/Consulting.css'

//for animation
import { motion } from "framer-motion"
import { grow } from '../../animation/variants/grow'
import { moveHorizontal } from '../../animation/variants/moveHorizontal'

import { ReactComponent as SeeMoreArrow } from '../consulting/resources/seemore_arrow.svg';

function ProductSeeMore({ metaDetails }) {
  let btext = metaDetails.link === null ? 'Coming Soon' : 'See More';

  if (!(metaDetails.link === null)) {
    return (<button className='product-see-more product-see-more-button-border component-row' onClick={() => { if (!(metaDetails.link === null)) window.open(metaDetails.link); }}>
      <h1>{btext}</h1>
      <SeeMoreArrow className='consulting-container-see-more-arrow' />
    </button>)
  }

  return (<div className='product-see-more-without-hover product-see-more-button-border component-row' onClick={() => { if (!(metaDetails.link === null)) window.open(metaDetails.link); }}>
    <h1>{btext}</h1>
  </div>)
}


function ProductImage({ path }) {
  return (<div id='pimage' className='product-image'>
    <img className='product-image' src={path}></img>
  </div>);
}

function ProductDeatils({ path }) {
  return (<div id='pimage' className='product-image'><img src={path}></img></div>);
}

function ProductMetaData({ metaDetails }) {
  return (<div className='product-meta'>
    <h1 className='product-meta-title'>{metaDetails.name}</h1>
    <div className='product-meta-details'>
      <p>{metaDetails.keyPoints}</p>
    </div>
    <ProductSeeMore metaDetails={metaDetails} />

  </div>);
}

function ProductImageLeft({ productObject }) {
  console.log("myObj", { productObject });
  return (
    <div id={productObject.idTag} className='product border-b'>
      <div className='product-component1'></div>
      <motion.div
        style={{ originX: 0, originY: 0 }}
        variants={grow('left', .7)}
        initial='before'
        whileInView='after'
        viewport={{ once: true }}
        className='border-r'></motion.div>
      <div className='product-component2'></div>
      <motion.div
        style={{ originX: 0, originY: 0 }}
        variants={grow('left', .7)}
        initial='before'
        whileInView='after'
        viewport={{ once: true }}
        className='border-r'></motion.div>
      <ProductMetaData metaDetails={productObject} />
      <motion.div
        style={{ originX: 0, originY: 0 }}
        variants={grow('left', .7)}
        initial='before'
        whileInView='after'
        viewport={{ once: true }} className='border-r'></motion.div>
      <motion.div
        variants={moveHorizontal(350, .8)}
        initial='before'
        whileInView='after'
      >
        <ProductImage path={productObject.image} />
      </motion.div>
    </div>
  )
}

function ProductImageRight({ productObject }) {
  return (
    <div id={productObject.idTag} className='product border-b'>
      <motion.div
        variants={moveHorizontal(-350, .8)}
        initial='before'
        whileInView='after'>
        <ProductImage path={productObject.image} />

      </motion.div>
      <motion.div
        style={{ originX: 0, originY: 0 }}
        variants={grow('left', .7)}
        initial='before'
        whileInView='after'
        viewport={{ once: true }}
        className='border-r'></motion.div>
      <ProductMetaData metaDetails={productObject} />
      <motion.div
        style={{ originX: 0, originY: 0 }}
        variants={grow('left', .7)}
        initial='before'
        whileInView='after'
        viewport={{ once: true }}
        className='border-r'></motion.div>
      <div className='product-component1'></div>
      <motion.div
        style={{ originX: 0, originY: 0 }}
        variants={grow('left', .7)}
        initial='before'
        whileInView='after'
        viewport={{ once: true }}
        className='border-r'></motion.div>
      <div className='product-component2'></div>
    </div>
  )
}

function Product({ productObject }) {
  return productObject.id % 2 === 1 ? ProductImageLeft({ productObject }) : ProductImageRight({ productObject });
}

export default Product
