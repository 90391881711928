// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.why-eclipticon-container {
    height: 616px;
    width: 1440px;
    color: rgba(255, 255, 255, 1);
    font-family: "poppins";
    font-style: normal;
  }

  .why-eclipticon-container-row1 {
    height: 206px;
  }

  .why-eclipticon-container-row2 {
    height: 410px;
  }

  .why-eclipticon-container-col1 {
    width: 240px;
  }

  .why-eclipticon-container-col2 {
    width: 760px;
  }

  .why-eclipticon-container-col3 {
    width: 240px;
  }

  .why-eclipticon-container-col4 {
    width: 200px;
  }

.why-eclipticon-title-box
{
  width: 337px;
  height: 72px;
  margin-top: 94px;
  margin-left: 408px;
}

.why-eclipticon-title-box h1
{
  font-weight: 275;
  font-size: 48px;
}


.why-eclipticon-details-box
{
  width: 636px;
  height: 216px;
  margin-left: 89px;
  margin-top: 71px;
}

.why-eclipticon-details-box h1
{
  font-weight: 275;
  font-size: 24px;
  text-align: right;
}

`, "",{"version":3,"sources":["webpack://./src/component/homepage/resources/WhyEclipticon.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,aAAa;IACb,6BAA6B;IAC7B,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;AAEF;;EAEE,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;;EAEE,gBAAgB;EAChB,eAAe;AACjB;;;AAGA;;EAEE,YAAY;EACZ,aAAa;EACb,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;;EAEE,gBAAgB;EAChB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n.why-eclipticon-container {\n    height: 616px;\n    width: 1440px;\n    color: rgba(255, 255, 255, 1);\n    font-family: \"poppins\";\n    font-style: normal;\n  }\n\n  .why-eclipticon-container-row1 {\n    height: 206px;\n  }\n\n  .why-eclipticon-container-row2 {\n    height: 410px;\n  }\n\n  .why-eclipticon-container-col1 {\n    width: 240px;\n  }\n\n  .why-eclipticon-container-col2 {\n    width: 760px;\n  }\n\n  .why-eclipticon-container-col3 {\n    width: 240px;\n  }\n\n  .why-eclipticon-container-col4 {\n    width: 200px;\n  }\n\n.why-eclipticon-title-box\n{\n  width: 337px;\n  height: 72px;\n  margin-top: 94px;\n  margin-left: 408px;\n}\n\n.why-eclipticon-title-box h1\n{\n  font-weight: 275;\n  font-size: 48px;\n}\n\n\n.why-eclipticon-details-box\n{\n  width: 636px;\n  height: 216px;\n  margin-left: 89px;\n  margin-top: 71px;\n}\n\n.why-eclipticon-details-box h1\n{\n  font-weight: 275;\n  font-size: 24px;\n  text-align: right;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
