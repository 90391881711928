import './Products.css'
import Product from './Product'
import '../commons/Common.css'
import ProductsJson from "../../jsons/products.json";
import FooterPart1 from '../footer/FooterPart1';
import { useEffect } from 'react';

function Products() {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      // Wait for the DOM to update before scrolling
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0); // Delay to ensure content is rendered
    }
  }, []);

  const renderProducts = () => {
    const data = [];
    {
      ProductsJson.map((item, i) => (
        data.push(<Product productObject={item} />)
      ))
    }
    return data;
  }
  
  return (
    <div>
      {renderProducts()}
      <FooterPart1 />
    </div>
  )
}

export default Products
