import React from 'react'
import './resources/Company.css'
import '../commons/Common.css'
import About from './About'
import Team from './Team'
import FooterPart1 from '../footer/FooterPart1'


function Company() {
  return (
    <div className='company'>
      <About></About>
      <Team></Team>
      <FooterPart1/>
    </div>
  )
}

export default Company