import React from 'react'
import './resources/Company.css'
import '../commons/Common.css'
import aboutJson from './resources/About.json'

function About() {
  return (
    <div className='about-container border-b'>
      <div className='blank-component border-r'></div>
      <div className='blank-component border-r'></div>
      <div className='about'>
        <div className='about-header border-b'><h1>{aboutJson.title}</h1></div>
        <div className='about-text'><p>{aboutJson.details}</p></div>
      </div>
    </div>
  )
}

export default About