// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.what-is-new-container
{
  height: 640px;
  width: 1440px;
  color: rgba(255, 255, 255, 1);
}

.what-is-new-container-col1
{
  transform-origin: 0 100%;
  width: 240px;
}

.what-is-new-container-col2
{
  width: 1002px;
}

.what-is-new-container-col3
{
  width: 198px;
}

.what-is-new-container-col21
{
    height: 640px;
    width: 482px;
}

.what-is-new-container-col22
{
    transform-origin: 100% 0;
    height: 640px;
    width: 518px;
}

.what-is-new-container-hotcard-box
{
    height: 628px;
    width: 498px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6px;
}

.what-is-new-container-text-box
{
    width: 270px;
    height: 72px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 320px;
}

.what-is-new-container-text-box h1
{
    font-family: 'Poppins';
    font-size: 48px;
    font-weight: 275;
    line-height: 72px;
    text-align: center;
}


`, "",{"version":3,"sources":["webpack://./src/component/homepage/resources/WhatIsNew.css"],"names":[],"mappings":"AAGA;;EAEE,aAAa;EACb,aAAa;EACb,6BAA6B;AAC/B;;AAEA;;EAEE,wBAAwB;EACxB,YAAY;AACd;;AAEA;;EAEE,aAAa;AACf;;AAEA;;EAEE,YAAY;AACd;;AAEA;;IAEI,aAAa;IACb,YAAY;AAChB;;AAEA;;IAEI,wBAAwB;IACxB,aAAa;IACb,YAAY;AAChB;;AAEA;;IAEI,aAAa;IACb,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;;IAEI,YAAY;IACZ,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;;IAEI,sBAAsB;IACtB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n\n.what-is-new-container\n{\n  height: 640px;\n  width: 1440px;\n  color: rgba(255, 255, 255, 1);\n}\n\n.what-is-new-container-col1\n{\n  transform-origin: 0 100%;\n  width: 240px;\n}\n\n.what-is-new-container-col2\n{\n  width: 1002px;\n}\n\n.what-is-new-container-col3\n{\n  width: 198px;\n}\n\n.what-is-new-container-col21\n{\n    height: 640px;\n    width: 482px;\n}\n\n.what-is-new-container-col22\n{\n    transform-origin: 100% 0;\n    height: 640px;\n    width: 518px;\n}\n\n.what-is-new-container-hotcard-box\n{\n    height: 628px;\n    width: 498px;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 6px;\n}\n\n.what-is-new-container-text-box\n{\n    width: 270px;\n    height: 72px;\n    margin-left: auto;\n    margin-right: auto;\n    margin-top: 320px;\n}\n\n.what-is-new-container-text-box h1\n{\n    font-family: 'Poppins';\n    font-size: 48px;\n    font-weight: 275;\n    line-height: 72px;\n    text-align: center;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
