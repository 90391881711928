import React from 'react'
import './resources/WhyEclipticon.css'
import '../commons/Common.css'
import whyEclipticonJson from './resources/WhyEclipticon.json'

import { motion } from "framer-motion"
// variants
import {move} from '../../animation/variants/variants'
import {grow} from '../../animation/variants/grow'

function WhyEclipticon() {
  return (
      <div className="why-eclipticon-container border-b">
        <div className='component-row border-b'>
          <div className=' why-eclipticon-container-row1 why-eclipticon-container-col1 border-r'></div>
          <div className='why-eclipticon-container-row1 why-eclipticon-container-col2 border-r'>
              <motion.div 
              variants ={move('left',1)} 
              initial = 'before'
              whileInView= 'after'
              viewport={{once:true}}
              className='why-eclipticon-title-box'>
                    <h1>{whyEclipticonJson.title}</h1>
              </motion.div>
          </div>
          <div className='why-eclipticon-container-row1 why-eclipticon-container-col3 bg-white border-r'>
            <div className='why-eclipticon-img'>
               <img src='_.jpg'></img>
            </div>
          </div>
          <div className='why-eclipticon-container-row1 why-eclipticon-container-col4 '></div>
        </div>
        <div className='component-row'>
          <div className=' why-eclipticon-container-row2 why-eclipticon-container-col1 border-r'></div>
          <div className='why-eclipticon-container-row2 why-eclipticon-container-col2 border-r'>
            <motion.div 
            variants ={move('left',1)} 
            initial = 'before'
            whileInView= 'after'
            viewport={{once:true}}
            className='why-eclipticon-details-box'>
                  <h1>{whyEclipticonJson.text}</h1>
            </motion.div>
            </div>
          <div className='why-eclipticon-container-row2 why-eclipticon-container-col3 border-r'></div>
          <div className='why-eclipticon-container-row2 why-eclipticon-container-col4 '></div>
        </div>
      </div>
  );
}

export default WhyEclipticon
