
export const moveHorizontal = (from, dura) => {

    return {
        before:
        {
            translateX: from,
            translateY: 0,
        },
        after:
        {
            translateX: 0,
            translateY: 0,
            transition:
            {
                duration: dura
            },
        },

    }

}
