import React from 'react'
import './resources/Service.css'
import './resources/Services.css'
import '../../commons/Common.css'
import FooterPart1 from '../../footer/FooterPart1'


function Service({ service, ServiceIcon }) {
  return (
    <div>
      <div className='service-container border-b'>
        <div className='component-row'>
          {ServiceIcon}
          <div className='service-title-box service-title-text'>{service.name}</div>
        </div>
        <div className='component-row'>
          <div className='service-detail-box service-detail-text'><p>{service.details}</p></div>
          <div className='service-image-box product-see-more-button-border'>
            <div className='service-image'></div>
          </div>
        </div>
        <div className='service-detail-summary-box service-detail-text'><p>{service.summary}</p></div>
      </div>
      <FooterPart1 />
    </div>
  )
}

export default Service
