import React from 'react'
import './resources/Services.css'
import engJson from './resources/Engineering.json'
import dsJson from './resources/DS.json'
import itJson from './resources/IT.json'
import admJson from './resources/ADM.json'
import pmJson from './resources/PM.json'
import marketingJson from './resources/Marketing.json'
import serviceMessage from'./resources/Services.json'
import {Link} from 'react-router-dom'

import FooterPart1 from '../../footer/FooterPart1'

import { ReactComponent as EngineeringIcon} from './resources/eng_icon.svg';
import { ReactComponent as DataScienceIcon} from './resources/data_sc_icon.svg';
import { ReactComponent as ProjectManagmentIcon} from './resources/project_man_icon.svg';
import { ReactComponent as ADMIcon} from './resources/adm_icon.svg';
import { ReactComponent as MarketingIcon} from './resources/marketing_icon.svg';
import { ReactComponent as SettingIcon} from './resources/clarity_settings-outline-badged.svg';



function Services() {
  return (<div>
              <div className='services-container border-b'>
      <div className=''>
        <nav className='component-row border-b-black'>
        <Link to='/services/engineering' className='services-text-style'>
          <div className='service-engineering-bg border-r-black'>
            <div className='service-engineering-bg-box'>
            <div className='component-col'>
            <div className='service-title-icon-box'>
              <EngineeringIcon  className='service-engineering-icon'></EngineeringIcon>
              <div className='service-engineering-text'>{engJson.name}</div>
            </div>
            <div className='service-engineering-summary services-summary-text-style'>{engJson.metaDataSummary}</div>
            </div>
            </div>
          </div>
        </Link>

        <Link to='/services/Data Science' className='services-text-style'>
          <div className='service-ds-bg border-r-black'>
            <div className='service-ds-bg-box'>
              <div className='component-col'>
            <div className='service-title-icon-box'>
            <DataScienceIcon  className='service-engineering-icon'></DataScienceIcon>
            <div className='service-ds-text'>{dsJson.name}</div>
            </div>
            <div className='service-ds-summary services-summary-text-style'>{dsJson.metaDataSummary}</div>
            </div>
            </div>
          </div>
        </Link>
        
        <Link to='/services/Project Management' className='services-text-style'>
          <div className='service-pm-bg border-r-black'>
          <div className='service-pm-bg-box'>
          <div className='component-col'>
              <div className='service-title-icon-box'>
                 <ProjectManagmentIcon  className='service-engineering-icon'></ProjectManagmentIcon>
                 <div className='service-ds-text'>{pmJson.name}</div>
              </div>
              <div className='service-pm-summary services-summary-text-style'>{pmJson.metaDataSummary}</div>
            </div>
          </div>
          </div>
        </Link>
        </nav>
        
        <nav className='component-row'>
        <Link to='/services/Application Development & Management' className='services-text-style'>
          <div className='service-adm-bg border-r-black'>
            <div className='service-adm-bg-box'>
                <div className='component-col'>
                    <div className='service-title-icon-box'>
                        <ADMIcon  className='service-engineering-icon'></ADMIcon>
                        <div className='service-adm-text'>{admJson.name}</div>
                    </div>
                    <div className='service-adm-summary services-summary-text-style'>{admJson.metaDataSummary}</div>
                </div>
            </div>
          </div>
        </Link>
        
        <Link to='/services/Marketing' className='services-text-style'>
          <div className='service-marketing-bg border-r-black'>
            <div className='service-marketing-bg-box'>
            <div className='component-col'>
            <div className='service-title-icon-box'>
            <MarketingIcon  className='service-engineering-icon'></MarketingIcon>
            <div className='service-marketing-text'>{marketingJson.name}</div>
            </div>
            <div className='service-marketing-summary services-summary-text-style'>{marketingJson.metaDataSummary}</div>
            </div>
            </div>
          </div>
        </Link>
        
        <Link to='/services/IT' className='services-text-style'>
        <div className='service-it-bg'>
            <div className='service-marketing-it-box'>
              <div className='component-col'>
                <div className='service-title-icon-box'>
                  <SettingIcon  className='service-engineering-icon'></SettingIcon>
                  <div className='services-text-style'>{itJson.name}</div>
                </div>
              <div className='service-it-summary services-summary-text-style'>{itJson.metaDataSummary}</div>
            </div>
            </div>
        </div>
        </Link>
        </nav>
        <div className='services-container-message-box services-container-message-text'>{serviceMessage.serviceMessage}</div>
      </div>
    </div>
    <FooterPart1/>

          </div>
  )
}

export default Services
